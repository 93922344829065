<template>
  <footer>
    <VDivider />
    <div class="container footer">
      <div class="first-block">
        <div>
          <div
            class="title"
            style="min-width: 170px;"
          >
            Vendre
          </div>
          <NuxtLink :to="'/vente-accompagnee'">
            <div>Vente accompagnée</div>
          </NuxtLink>
          <NuxtLink :to="'/'">
            <div>Vente instantanée</div>
          </NuxtLink>
        </div>
        <div>
          <div
            class="title"
            style="min-width: 170px;"
          >
            Nos services
          </div>
          <div
            class="acheter"
          >
            <p
              class="acheter__p"
              @click="toggleMenu"
            >
              Acheter
              <SvgChevronRight class="acheter__chevronRight" />
            </p>
            <div
              v-if="isMenuOpen"
              ref="menuAcheter"
              class="acheter__menu"
            >
              <div
                v-for="branch in branchesModel"
                :key="branch.slug"
                class="acheter__city"
              >
                <p @click="toggleMenuCity(branch)">
                  à {{ branch.name }}
                  <SvgChevronRight class="acheter__chevronRight" />
                </p>
                <div
                  v-if="isMenuCityOpen && cityCLicked === branch.name"
                  class="acheter__menuCity"
                >
                  <NuxtLink
                    :to="`/acheter/annonces/region/${branch.slug}`"
                    @click="toggleMenu"
                  >
                    <p>Acheter à {{ branch.name }}</p>
                  </NuxtLink>
                  <NuxtLink
                    :to="`/acheter/annonces/region/${branch.slug}?estate_type=flat`"
                    @click="toggleMenu"
                  >
                    <p>Acheter un appartement à {{ branch.name }}</p>
                  </NuxtLink>
                  <NuxtLink
                    :to="`/acheter/annonces/region/${branch.slug}?estate_type=house`"
                    @click="toggleMenu"
                  >
                    <p>Acheter une maison à {{ branch.name }}</p>
                  </NuxtLink>
                </div>
              </div>
            </div>
          </div>

          <NuxtLink :to="'/'">
            <div>Vendre</div>
          </NuxtLink>
          <NuxtLink :to="`/estimer`">
            <div>Valoriser mon futur achat</div>
          </NuxtLink>
          <NuxtLink :to="'/prestige'">
            <div>Prestige</div>
          </NuxtLink>
          <NuxtLink :to="'/financement'">
            <div>Financement</div>
          </NuxtLink>
        </div>
        <div>
          <div
            class="title"
            style="min-width: 170px;"
          >
            A propos
          </div>
          <NuxtLink :to="'/qui-sommes-nous'">
            <div>Qui sommes-nous ?</div>
          </NuxtLink>
          <NuxtLink :to="'/faq'">
            <div>FAQ</div>
          </NuxtLink>
          <NuxtLink
            :to="'https://homeloop.fr/blog/'"
          >
            <div>Nos conseils d’experts</div>
          </NuxtLink>
          <NuxtLink :to="'/recrutement'">
            <div>Rejoindre homeloop</div>
          </NuxtLink>
          <NuxtLink
            :to="'/on-recrute'"
          >
            <div>Devenir mandataire homeloop</div>
          </NuxtLink>
          <NuxtLink :to="'/contact'">
            <div>Nous contacter</div>
          </NuxtLink>
        </div>
      </div>
      <div class="divider" />
      <div class="second-block">
        <div class="title">
          Nous suivre
        </div>
        <NuxtLink :to="'https://www.facebook.com/homeloopFR/'">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              viewBox="0 0 320 512"
            >
              <path
                opacity="1"
                d="M80 299.3V512H196V299.3h86.5l18-97.8H196V166.9c0-51.7 20.3-71.5 72.7-71.5c16.3 0 29.4 .4 37 1.2V7.9C291.4 4 256.4 0 236.2 0C129.3 0 80 50.5 80 159.4v42.1H14v97.8H80z"
              />
            </svg>
            <p class="ms-2">Facebook</p>
          </div>
        </NuxtLink>
        <NuxtLink :to="'https://www.instagram.com/homeloop/'">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              viewBox="0 0 448 512"
            >
              <path
                opacity="1"
                d="M224.1 141c-63.6 0-114.9 51.3-114.9 114.9s51.3 114.9 114.9 114.9S339 319.5 339 255.9 287.7 141 224.1 141zm0 189.6c-41.1 0-74.7-33.5-74.7-74.7s33.5-74.7 74.7-74.7 74.7 33.5 74.7 74.7-33.6 74.7-74.7 74.7zm146.4-194.3c0 14.9-12 26.8-26.8 26.8-14.9 0-26.8-12-26.8-26.8s12-26.8 26.8-26.8 26.8 12 26.8 26.8zm76.1 27.2c-1.7-35.9-9.9-67.7-36.2-93.9-26.2-26.2-58-34.4-93.9-36.2-37-2.1-147.9-2.1-184.9 0-35.8 1.7-67.6 9.9-93.9 36.1s-34.4 58-36.2 93.9c-2.1 37-2.1 147.9 0 184.9 1.7 35.9 9.9 67.7 36.2 93.9s58 34.4 93.9 36.2c37 2.1 147.9 2.1 184.9 0 35.9-1.7 67.7-9.9 93.9-36.2 26.2-26.2 34.4-58 36.2-93.9 2.1-37 2.1-147.8 0-184.8zM398.8 388c-7.8 19.6-22.9 34.7-42.6 42.6-29.5 11.7-99.5 9-132.1 9s-102.7 2.6-132.1-9c-19.6-7.8-34.7-22.9-42.6-42.6-11.7-29.5-9-99.5-9-132.1s-2.6-102.7 9-132.1c7.8-19.6 22.9-34.7 42.6-42.6 29.5-11.7 99.5-9 132.1-9s102.7-2.6 132.1 9c19.6 7.8 34.7 22.9 42.6 42.6 11.7 29.5 9 99.5 9 132.1s2.7 102.7-9 132.1z"
              />
            </svg>
            <p class="ms-2">Instagram</p>
          </div>
        </NuxtLink>
        <NuxtLink :to="'https://www.linkedin.com/company/homeloop/'">
          <div>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="16"
              viewBox="0 0 448 512"
            >
              <path
                opacity="1"
                d="M100.3 448H7.4V148.9h92.9zM53.8 108.1C24.1 108.1 0 83.5 0 53.8a53.8 53.8 0 0 1 107.6 0c0 29.7-24.1 54.3-53.8 54.3zM447.9 448h-92.7V302.4c0-34.7-.7-79.2-48.3-79.2-48.3 0-55.7 37.7-55.7 76.7V448h-92.8V148.9h89.1v40.8h1.3c12.4-23.5 42.7-48.3 87.9-48.3 94 0 111.3 61.9 111.3 142.3V448z"
              />
            </svg>
            <p class="ms-2">LinkedIn</p>
          </div>
        </NuxtLink>
        <section>
          <VBtn
            color="primary"
            variant="outlined"
            size="small"
            class="partner-button"
            :to="'/partenaires'"
          >
            Mon espace partenaire
            <SvgArrowRight class="arrow" />
          </VBtn>
        </section>
        <NuxtLink :to="'/mentions-legales'">
          <div class="legal-mentions">
            Mentions légales et CGU<br>
            © homeloop {{ new Date().getFullYear() }}. Tous droits réservés.
          </div>
        </NuxtLink>
      </div>
    </div>
    <VDivider />
    <div class="container logo">
      <SvgLogoBlue />
    </div>
  </footer>
</template>

<script setup lang="ts">
import { onClickOutside } from '@vueuse/core'
import type { BranchModel } from '~/types'
const { api } = useFeathers()
const branchesService = api.service('branches')

const { data: branches } = await useAsyncData<Array<BranchModel>>('branches', async () => {
  return await branchesService.find({ query: { listed_on_website: true, $limit: -1, $sort: { name: 1 } } })
})

const branchesModel = branches.value || []

const isMenuOpen = ref(false)
const isMenuCityOpen = ref(false)
const cityCLicked = ref('')
const menuAcheter = ref(null)

onClickOutside(menuAcheter, () => {
  toggleMenu()
})

function toggleMenu () {
  isMenuOpen.value = !isMenuOpen.value
  isMenuCityOpen.value = false
}

function toggleMenuCity (item:BranchModel) {
  if (cityCLicked.value === item.name) {
    isMenuCityOpen.value = !isMenuCityOpen.value
  } else {
    isMenuCityOpen.value = true
    cityCLicked.value = item.name
  }
}
</script>

<style lang="scss" scoped>
.acheter {
  position: relative;
  width: fit-content;

  &__p {
    cursor: pointer;
  }

  &__chevronRight {
    stroke: black;
    margin-left: 0.5em;
    height: 10px;
  }

  &__menu {
    position: absolute;
    background-color: white;
    box-shadow: 0 4px 24px 0 rgba(0 0 0 / 25%);
    z-index: 1;
    width: max-content;
  }

  &__city {
    position: relative;
    padding-left: 10px;
    padding-right: 10px;
    cursor: pointer;
  }

  &__menuCity {
    box-shadow: 0 4px 24px 0 rgba(0 0 0 / 25%);
    position: absolute;
    background-color: white;
    padding: 10px;
    z-index: 2;
    width: max-content;
    left: 100%;
    top: 0;
  }

  @media (width >= 640px) {
    &__city:nth-last-child(1) {
      div {
        top:auto;
        bottom: 0;
      }
    }

    &__menu {
      top: -80px;
      left: 120%;
    }
  }

  @media (width <= 640px) {
    &__menuCity {
      right: 0;
      left: auto;
      top: auto;
    }
  }
}

footer {
  background: $white;
}

.footer {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  font-size: 1rem;
  line-height: 2.5em;
  color: $night-blue;

  @include media-breakpoint-down(sm) {
    flex-direction: column;
  }

  a {
    color: $night-blue;
    text-decoration: none;
  }

  .first-block {
    flex-grow: 1;
    display: flex;
    flex-flow: row wrap;
    justify-content: space-between;
    padding: 42px 0;
  }

  .divider {
    --divider-margin: calc(min(30px, 2.5vw));

    display: block;
    width: 1px;
    height: unset;
    background: black;
    opacity: 0.12;
    margin: 0 var(--divider-margin);

    @include media-breakpoint-down(sm) {
      margin: 0;
      width: unset;
      height: 1px;
    }
  }

  .second-block {
    flex-grow: 1;
    padding: 42px 0;

    div {
      display: flex;
      align-items: center;

      svg {
        fill: $purple;
        height: 18.5px;
        width: 16px;
      }
    }
  }

  .title {
    color: $purple;
    font-weight: 600;
  }

  .partner-button {
    font-weight: 600;
    text-transform: none;
    letter-spacing: 0;

    .arrow {
      stroke: $purple;
      margin-left: 0.5em;
    }
  }

  .legal-mentions {
    margin-top: 1em;
    color: $purple;
    font-size: 0.75rem;
    line-height: 1.5em;
  }
}

.logo {
  margin-top: 2rem;
  margin-bottom: 1em;
}

</style>
