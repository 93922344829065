<template>
  <svg
    width="9"
    height="16"
    viewBox="0 0 9 16"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
  >
    <path
      d="M1 1L8 8L1 15"
      stroke="#09090B"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </svg>
</template>
